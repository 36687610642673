// src/components/ProductCard.js
import React from "react";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import { BsStarFill } from "react-icons/bs";

const ProductCard = ({ product }) => {
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const fractionStar = rating % 1;

    const stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <span
          key={i}
          className="star-icon mx-1"
          style={{ display: "inline-block", overflowX: "hidden" }}
        >
          <BsStarFill />
        </span>
      );
    }

    if (fractionStar > 0) {
      const width = `${fractionStar * 14}px`; // Each star is 14 pixels wide
      stars.push(
        <span
          key="fraction"
          className="star-icon mx-1"
          style={{ width: width, display: "inline-block", overflowX: "hidden" }}
        >
          <BsStarFill />
        </span>
      );
    }

    const fullRating = rating.toFixed(2); // Full rating with decimal places
    return (
      <div className="star-wrapper" style={{ overflowX: "hidden" }}>
        <small className="text-muted" title={`Rating: ${fullRating} stars`}>
          {stars}
        </small>
      </div>
    );
  };

  return (
    <Card className="product-card h-100">
      <Carousel interval={null}>
        {product.images.map((image, index) => (
          <Carousel.Item key={index}>
            <div
              className="carousel-image blur"
              style={{
                backgroundImage: `url(${image})`,
                paddingTop: "56.25%", // 16:9 aspect ratio
                backgroundSize: "cover",
                position: "relative",
                filter: "blur(15px)", // Added blur filter
              }}
            ></div>
            {/* Contained overlay */}
            <div
              className="contained-overlay"
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat", // Added background repeat
                backgroundPosition: "50%", // Added background position
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                // filter: 'blur(15px)', // Removed blur filter
              }}
            ></div>
          </Carousel.Item>
        ))}
      </Carousel>

      <Card.Body>
        <Card.Title>{product.title}</Card.Title>
        <Card.Text>
          <strong>Brand:</strong> {product.brand} <br />
          <strong>Original Price:</strong> ${product.price.toFixed(2)} <br />
          <strong>Discounted Price:</strong> $
          {(product.price * (1 - product.discountPercentage / 100)).toFixed(2)}{" "}
          <br />
        </Card.Text>
      </Card.Body>
      <Card.Footer className="text-center">
        {renderStars(product.rating)}
      </Card.Footer>
    </Card>
  );
};

export default ProductCard;

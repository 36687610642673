// src/components/ProductGrid.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProductCard from './ProductCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

// Helper function to capitalize the first letter of each word in a sentence
function capitalizeSentenceCase(str) {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const ProductGrid = () => {
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [sortOption, setSortOption] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://dummyjson.com/products?limit=100');
        setResponseData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (
    !responseData ||
    !Array.isArray(responseData.products) ||
    responseData.products.length === 0
  ) {
    console.error('Invalid or empty data in the response:', responseData);
    return <p>Error loading data.</p>;
  }

  const filteredProducts = responseData.products.filter((product) => {
    return (
      product.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (categoryFilter === '' || product.category.toLowerCase() === categoryFilter.toLowerCase())
    );
  });

  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortOption === 'price-low-high') {
      return a.price - b.price;
    } else if (sortOption === 'price-high-low') {
      return b.price - a.price;
    } else if (sortOption === 'rating-high-low') {
      return b.rating - a.rating;
    } else if (sortOption === 'rating-low-high') {
      return a.rating - b.rating;
    } else {
      return 0;
    }
  });

  return (
    <div>
      <Navbar expand="lg" className="mb-3">
        <Container>
          <Navbar.Brand>Product Grid</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end py-2'>
            <Nav className="mr-auto">
              <FormControl
                className='w-sm-100'
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <select
                className="form-select mx-lg-2 my-2 my-lg-0"
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
              >
                <option value="">All Categories</option>
                {Array.from(new Set(responseData.products.map((product) => product.category))).map(
                  (category) => (
                    <option key={category} value={category}>
                      {capitalizeSentenceCase(category)}
                    </option>
                  )
                )}
              </select>
              <select
                className="form-select"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
              >
                <option value="">Sort by</option>
                <option value="price-low-high">Price: Low to High</option>
                <option value="price-high-low">Price: High to Low</option>
                <option value="rating-high-low">Rating: High to Low</option>
                <option value="rating-low-high">Rating: Low to High</option>
              </select>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container>
        <Row xs={1} md={3} xl={4} className="g-4">
          {sortedProducts.map((product) => (
            <Col key={product.id}>
              <ProductCard product={product} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ProductGrid;
